import * as React from 'react';

import { SuccessModal } from 'components/blocks/success-modal';
import { Container } from 'components/shared/container';
import { getTextWithoutParagraph } from 'utils/get-text-without-paragraph';

import {
  Wrapper,
  Header,
  StyledTitle,
  Description,
  Box,
  Information,
  InformationItem,
  InformationIcon,
  InformationContent,
  InformationTitle,
  InformationDescription,
  StyledTriangle,
} from './contact-form.styles';
import ExistingIcon from './images/existing.inline.svg';
import GiftCardIcon from './images/gift-card.inline.svg';
import PotentialIcon from './images/potential.inline.svg';
import { LogosBlock } from './logos-block';

const iconsCollection = {
  'existing-customers': ExistingIcon,
  'potential-customers': PotentialIcon,
  'gift-card': GiftCardIcon,
};

export const FormView = (props) => {
  const { title, description, formInformationItems, logosTitle, logosItems } = props;

  const [successMessage, setSuccessMessage] = React.useState(null);

  return (
    <Wrapper>
      <Container>
        <Header>
          <StyledTitle size="xl">{title}</StyledTitle>
          <Description
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(description),
            }}
          />
        </Header>

        <Box>
          <Information>
            {formInformationItems.map(({ title, description, iconName }, index) => {
              const Icon = iconsCollection[iconName];
              return (
                <InformationItem key={index}>
                  <InformationIcon>
                    <Icon />
                  </InformationIcon>
                  <InformationContent>
                    <InformationTitle as="h3" size="sm">
                      {title}
                    </InformationTitle>
                    <InformationDescription
                      dangerouslySetInnerHTML={{
                        __html: getTextWithoutParagraph(description),
                      }}
                    />
                  </InformationContent>
                </InformationItem>
              );
            })}
          </Information>
        </Box>

        <LogosBlock title={logosTitle} items={logosItems} />
      </Container>

      <StyledTriangle />

      {successMessage && (
        <SuccessModal message={successMessage} onClose={setSuccessMessage.bind(null, null)} />
      )}
    </Wrapper>
  );
};
