import styled from 'styled-components';

import { fontSize, media, color } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

import Triangle from './images/contact-form-triangle.svg';

export const InformationDescription = styled.p`
  font-size: ${fontSize.sm};
  color: #6b7696;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 0;

  ${media.sm} {
    font-size: ${fontSize.xs};
    line-height: 24px;
  }

  a {
    color: ${color.primary};
    text-decoration: none;
  }
`;

export const InformationTitle = styled(Title)`
  font-weight: 400;
  margin-bottom: 5px;
`;

export const InformationContent = styled.div``;

export const InformationIcon = styled.div`
  margin-bottom: 10px;
`;

export const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  border-right: 1px solid #e3e5eb;

  ${media.lg} {
    padding: 40px;
  }

  ${media.md} {
    align-items: center;
    text-align: center;
    border-right: none;
    border-bottom: 1px solid #e3e5eb;
  }

  ${media.sm} {
    padding: 25px;
  }

  &:last-child {
    border: 0;
  }
`;

export const Information = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${media.md} {
    grid-template-columns: 1fr;
  }
`;

export const FormWrapper = styled.div`
  padding: 50px;

  ${media.lg} {
    padding: 40px;
  }

  ${media.sm} {
    padding: 25px;
  }
`;

export const StyledTriangle = styled(Triangle)`
  position: absolute;
  top: 0;
  right: -110px;
  transform: translateY(-18%);
  z-index: -1;

  ${media.xl} {
    right: -330px;
  }

  ${media.md} {
    top: 50%;
    transform: translateY(-50%);
  }

  ${media.xs} {
    right: -250px;
  }
`;

export const BoxTitle = styled(Title)`
  font-weight: 400;
  margin-bottom: 30px;
`;

export const Box = styled.div`
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 2px 2px 12px rgba(26, 26, 67, 0.05), 14px 36px 120px -15px rgba(50, 50, 93, 0.1);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 60px;

  ${media.md} {
    display: block;
  }

  ${media.sm} {
    margin-bottom: 35px;
  }
`;

export const Description = styled.p`
  font-size: ${fontSize.md};
  color: #6b7696;
  line-height: 32px;
  max-width: 630px;
  margin: 0 auto;

  br {
    ${media.sm} {
      display: none;
    }
  }

  a {
    color: ${color.primary};
    text-decoration: none;
  }
`;

export const StyledTitle = styled(Title)`
  font-weight: 400;
  margin-bottom: 20px;

  ${media.sm} {
    font-size: 36px;
  }
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 60px;

  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 170px;
  overflow: hidden;

  ${media.sm} {
    padding-top: 100px;
  }
`;
