import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Form } from 'components/pages/contact/form';

import { buildPageMetadata } from '../utils/data-builders';

const Contact = ({ data: { wpPage } }) => {
  const { title, description, formTitle, formInformation, logos, showFooter } = wpPage.acf;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Form
        title={title}
        description={description}
        formTitle={formTitle}
        formInformationItems={formInformation}
        logosTitle={logos.title}
        logosItems={logos.images}
      />
      {showFooter !== 'false' && <Footer theme="light" />}
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        title
        description
        formTitle
        formInformation {
          title
          description
          iconName
        }

        logos {
          title
          images {
            id
            altText
            localFile {
              childImageSharp {
                fluid {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        showFooter
      }
    }
  }
`;
