import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FormView } from './contact-form.view';

export class Form extends React.Component {
  state = {
    activeFormName: 'launching-project',
  };

  changeActiveFormName = (newActiveFormName) =>
    this.setState({ activeFormName: newActiveFormName });

  render() {
    const { activeFormName } = this.state;
    const { title, description, formTitle, formInformationItems, logosTitle, logosItems } =
      this.props;

    return (
      <StaticQuery
        query={graphql`
          {
            allGfForm(filter: { cssClass: { eq: "contact-us" } }) {
              edges {
                node {
                  formId
                  apiURL
                  formFields {
                    id
                    label
                    type
                    defaultValue
                    isRequired
                    choices
                    conditionalLogic
                    description
                    cssClass
                  }
                  confirmations {
                    message
                  }
                  button {
                    text
                  }
                }
              }
            }
          }
        `}
        render={({ allGfForm: { edges } }) => (
          <FormView
            title={title}
            description={description}
            formTitle={formTitle}
            formInformationItems={formInformationItems}
            formsData={edges}
            activeFormName={activeFormName}
            onFormSwitcherItemClick={this.changeActiveFormName}
            logosTitle={logosTitle}
            logosItems={logosItems}
          />
        )}
      />
    );
  }
}
