import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { getFluidImage } from 'utils';

import { Wrapper, LogoWrapper, StyledTitle, ContentWrapper } from './contact-logos-block.styles';

export const LogosBlock = ({ title, items }) => {
  const logoItems = items.map(({ id, altText, localFile }) => {
    const fluid = getFluidImage(localFile);
    return (
      <LogoWrapper key={id}>
        <GatsbyImage
          style={{
            width: fluid.presentationWidth / 2,
            height: fluid.presentationHeight / 2,
          }}
          fluid={fluid}
          alt={altText}
          key={id}
        />
      </LogoWrapper>
    );
  });

  return (
    <Wrapper>
      <StyledTitle as="h2" size="xs">
        {title}
      </StyledTitle>
      <ContentWrapper>{logoItems}</ContentWrapper>
    </Wrapper>
  );
};
