import styled from 'styled-components';

import { media, fontSize } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const StyledTitle = styled(Title)`
  font-weight: 400;
  text-align: center;
  margin-bottom: 25px;

  ${media.sm} {
    font-size: ${fontSize.md};
  }
`;

export const LogoWrapper = styled.div`
  .gatsby-image-wrapper {
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 60px;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  column-gap: 20px;
  justify-content: center;
  align-items: center;

  ${media.sm} {
    grid-template-columns: repeat(2, max-content);
  }
`;
